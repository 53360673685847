"use client";

import React from "react";
import * as Sentry from "@sentry/nextjs";
import Button from "ui/atoms/Button/Button";

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  Sentry.captureException(error);

  return (
    <div className={"min-h-screen bg-slate-950 text-white py-8 md:py-20 px-2"}>
      <div className={"max-w-xl mx-auto"}>
        <h1 className={"font-brik text-5xl text-red-500"}>
          Something went wrong!
        </h1>

        <p className={"mb-4"}>
          Sorry that you are seeing this message. We've been alerted of the
          error and will hopefully get a fix up soon! In the meantime you can
          click the button below to retry. If that doesn't work we recommend you
          refresh the browser.
        </p>

        <Button theme="green" size="large" onClick={reset}>
          Try again
        </Button>
      </div>
    </div>
  );
}
