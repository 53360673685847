import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ElementType,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from "react";
import classNames from "classnames";
import * as shadowMakers from "../../../util/shadowMakers";

export interface Props {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  theme: keyof typeof theming;
  block?: boolean;
  active?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  outline?: boolean;
  icon?: (props: { className: string }) => JSX.Element | null;
  className?: string;
  overflow?: "ellipsis" | "wrap";
  type?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >["type"];
  as?: ElementType;
  href?: string;
  size?: "large" | "base";
  loading?: boolean;
  id?: string;
  popover?: ReactNode;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
}

export default forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  function Button(
    {
      children,
      theme,
      block,
      active,
      hovered,
      disabled,
      onClick,
      outline,
      icon,
      className,
      overflow = "ellipsis",
      type,
      href,
      as: Tag = href ? "a" : "button",
      size = "base",
      loading,
      id,
      onMouseEnter,
    }: Props,
    ref
  ) {
    const isActivatable = active !== undefined;
    const isHovered = isActivatable && hovered;
    const isActive = isActivatable && (active || !!isHovered);
    const hasShadow = !outline;

    // Disable button if loading
    if (loading) {
      disabled = true;
    }

    // Color classes
    const shadowColors = classNames(
      disabled ? "bg-gray-700" : theming[theme].shadow
    );
    const faceColors = classNames(
      "border",
      className,
      disabled
        ? "bg-gray-400 text-gray-500 border-gray-400"
        : [
            outline
              ? Object.values(theming[theme].outline)
              : [
                  Object.values(theming[theme].solid),
                  isActive ? theming[theme].bgActive : theming[theme].bg,
                ],
          ]
    );
    const activeDotColors = classNames(
      disabled && "bg-gray-500",
      !disabled && isHovered
        ? theming[theme].active.hovered
        : isActive
        ? theming[theme].active.on
        : theming[theme].active.off
    );

    return (
      <Tag
        onTouchStart={() => {}}
        type={type}
        href={href}
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        className={classNames(
          "relative inline-block max-w-full rounded focus:outline-none",
          shadowMakers.parentTransform({
            active: isActive,
            disabled: disabled === true,
            hasShadow,
          }),
          block && "w-full"
        )}
        id={id}
      >
        {hasShadow && (
          <div
            className={classNames(
              "rounded",
              shadowColors,
              shadowMakers.shadowTransform({
                active: isActive,
                disabled: !!disabled,
              })
            )}
          />
        )}

        <div
          className={classNames(
            "flex w-full max-w-full items-center overflow-hidden rounded text-left font-brik",

            size === "large" && [
              "px-4 text-2xl",
              // make the buttons actual height 40px (outline doesn't have a shadow) when overflow is wrap don't set any height
              overflow === "ellipsis" && outline
                ? "mb-[1px] h-[54px]"
                : "h-[50px]",
            ],
            size === "base" && [
              "px-2 text-lg",
              // make the buttons actual height 40px (outline doesn't have a shadow) when overflow is wrap don't set any height
              overflow === "ellipsis" && outline ? "h-[38px]" : "h-[34px]",
            ],

            shadowMakers.faceTransform({
              active: outline ? false : isActive,
              disabled: !!disabled,
            }),
            faceColors
          )}
        >
          {isActivatable && (
            <span
              className={classNames(
                "inline-block rounded-full",
                children && "mr-2",
                size === "large" &&
                  "h-[20px] min-h-[20px] w-[20px] min-w-[20px]",
                size === "base" &&
                  "h-[16px] min-h-[16px] w-[16px] min-w-[16px]",

                activeDotColors
              )}
            />
          )}

          {children && (
            <div
              className={classNames(
                "inline-block w-full leading-tight",
                overflow === "ellipsis"
                  ? "overflow-hidden text-ellipsis whitespace-nowrap"
                  : "py-[5px]", // make the button exactly 40px when it is not wrapped
                block && "text-center"
              )}
            >
              {loading ? "Loading" : children}
            </div>
          )}

          {icon?.({
            className: classNames(
              size === "base" && {
                "ml-2 h-[20px] min-h-[20px] w-[20px] min-w-[20px]": children,
                "h-[24px] min-h-[24px] w-[24px] min-w-[24px]": !children,
                "ml-2": !children && active !== undefined,
              },
              size === "large" && {
                "ml-2 h-[28px] min-h-[28px] w-[28px] min-w-[28px]": children,
                "h-[28px] min-h-[28px] w-[28px] min-w-[28px]": !children,
                "ml-2": !children && active !== undefined,
              }
            ),
          })}
        </div>
      </Tag>
    );
  }
);

const theming = {
  default: {
    bgActive: "bg-slate-400 border-slate-400",
    shadow: "bg-slate-500",
    bg: "bg-slate-300 border-slate-300",
    solid: {
      text: "text-neutral-900",
      bgGroupActive: "group-active:bg-slate-400 group-active:border-slate-400",
    },
    outline: {
      text: "text-slate-300",
      bg: "transparent border-slate-300",
    },
    active: {
      on: "bg-red-500",
      off: "bg-gray-500",
      hovered: "bg-white",
    },
  },
  green: {
    bgActive: "bg-emerald-600 border-emerald-600",
    shadow: "bg-emerald-600",
    bg: "bg-emerald-500 border-emerald-500",
    solid: {
      text: "text-white",
      bgGroupActive:
        "group-active:bg-emerald-500 group-active:border-emerald-500",
    },
    outline: {
      text: "text-emerald-400",
      bg: "transparent border-emerald-400",
    },
    active: {
      on: "bg-red-500",
      off: "bg-gray-500",
      hovered: "bg-white",
    },
  },
  red: {
    bgActive: "bg-red-500 border-red-500",
    shadow: "bg-red-600",
    bg: "bg-red-400 border-red-400",
    solid: {
      text: "text-white",
      bgGroupActive: "group-active:bg-red-500 group-active:border-red-500",
    },
    outline: {
      text: "text-red-400",
      bg: "transparent border-red-400",
    },
    active: {
      on: "bg-red-900",
      off: "bg-gray-500",
      hovered: "bg-white",
    },
  },
  blue: {
    bgActive: "bg-blue-500 border-blue-500",
    shadow: "bg-blue-600",
    bg: "bg-blue-400 border-blue-400",
    solid: {
      text: "text-white",
      bgGroupActive: "group-active:bg-blue-500 group-active:border-blue-500",
    },
    outline: {
      text: "text-blue-400",
      bg: "transparent border-blue-400",
    },
    active: {
      on: "bg-red-500",
      off: "bg-gray-500",
      hovered: "bg-white",
    },
  },
  yellow: {
    bgActive: "bg-yellow-500 border-yellow-500",
    shadow: "bg-yellow-600",
    bg: "bg-yellow-400 border-yellow-400",
    solid: {
      text: "text-white",
      bgGroupActive:
        "group-active:bg-yellow-500 group-active:border-yellow-500",
    },
    outline: {
      text: "text-yellow-400",
      bg: "transparent border-yellow-400",
    },
    active: {
      on: "bg-red-500",
      off: "bg-gray-500",
      hovered: "bg-white",
    },
  },
  purple: {
    bgActive: "bg-purple-500 border-purple-500",
    shadow: "bg-purple-600",
    bg: "bg-purple-400 border-purple-400",
    solid: {
      text: "text-white",
      bgGroupActive:
        "group-active:bg-purple-500 group-active:border-purple-500",
    },
    outline: {
      text: "text-purple-400",
      bg: "transparent border-purple-400",
    },
    active: {
      on: "bg-red-500",
      off: "bg-gray-500",
      hovered: "bg-white",
    },
  },

  white: {
    bgActive: "bg-gray-300 border-gray-300",
    shadow: "bg-gray-400",
    bg: "bg-white border-white",
    solid: {
      text: "text-neutral-900",
      bgGroupActive: "group-active:bg-gray-300 group-active:border-gray-300",
    },
    outline: {
      text: "text-white",
      bg: "transparent border-white",
    },
    active: {
      on: "bg-red-500",
      off: "bg-gray-500",
      hovered: "bg-black",
    },
  },
};
