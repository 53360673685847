import classNames from "classnames";

export function parentTransform({
  active,
  disabled,
  hasShadow,
}: {
  active: boolean;
  disabled: boolean;
  hasShadow: boolean;
}) {
  return classNames(
    "group z-0",
    disabled
      ? [disabled && "cursor-not-allowed"]
      : [active && "active:translate-y-0.5"],
    hasShadow && "mb-1.5"
  );
}

export function shadowTransform({
  active,
  disabled,
}: {
  active: boolean;
  disabled: boolean;
}) {
  return classNames(
    "absolute top-0 left-0 right-2 bottom-0 -z-10 w-full",
    !disabled && [
      "transition-transform",
      active ? "-translate-y-0 group-active:-translate-y-0" : "translate-y-1",
    ]
  );
}

export function faceTransform({
  active,
  disabled,
}: {
  active: boolean;
  disabled: boolean;
}) {
  return classNames(
    !disabled && [
      "transition-all",
      active
        ? "translate-y-0.5 group-active:translate-y-0.5"
        : "group-active:translate-y-1.5",
    ]
  );
}
